import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Icon, IconButton, TableSortLabel} from '@material-ui/core';
import { getFormattedDate } from '../../../utilities/date_util';
import { GetLicenseAppointmentStatusIcon, useStylesLicenseAppointment } from '../../../utilities/agentLicenseAppointment_util';
import { Strings } from '../../../assets/common/strings';
import _ from 'lodash';
import LAStatusIconLegendDialog from '../la_status_icon_legend_dialog';

export default function LicenseTable({ columns, rows }) {

  const classes = useStylesLicenseAppointment();

  // For License status information box
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  // For License sort
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('State');

  const handleDialogOpen = () => {
    setStatusDialogOpen(true);
  };

  const handleDialogClose = () => {
    setStatusDialogOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = rows.sort((rowx, rowy) => {
    if (orderBy === 'State') {
      return order === 'asc' ? rowx.State.localeCompare(rowy.State) : rowy.State.localeCompare(rowx.State);
    } else if (orderBy === 'Status') {
      return order === 'asc' ? rowx.StatusText.localeCompare(rowy.StatusText) : rowy.StatusText.localeCompare(rowx.StatusText);
    } else if (orderBy === 'Expiration') {

      const expirationDateX = rowx.ExpirationDate ? new Date(rowx.ExpirationDate) : null;
      const expirationDateY = rowy.ExpirationDate ? new Date(rowy.ExpirationDate) : null;

      if (!_.isNil(expirationDateX) && !_.isNil(expirationDateY)) {
        return order === 'asc' ? expirationDateX.getTime() - expirationDateY.getTime() : expirationDateY.getTime() - expirationDateX.getTime();
      }
      if (expirationDateX === null) return order === 'asc' ? 1 : -1;
      if (expirationDateY === null) return order === 'asc' ? -1 : 1;

    } else if (orderBy === 'Line Of Authority') {
      return order === 'asc' ? rowx.LineOfLicenseText.localeCompare(rowy.LineOfLicenseText) : rowy.LineOfLicenseText.localeCompare(rowx.LineOfLicenseText);
    }
    return 0;
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="license table">

          <TableHead>
            <TableRow>
              {columns.map((col) => {
                if (col.key == "StatusText") {
                  return <TableCell align="center" key={col.key} className={classes.bold_text} >
                    <div className={classes.position}>
                      <IconButton onClick={handleDialogOpen}>
                        <Icon className={classes.iconCheck}>info_outline</Icon>
                      </IconButton>

                      <TableSortLabel className={classes.bold_text}
                        active={orderBy === col.name}
                        direction={orderBy === col.name ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, col.name)}
                      >{col.name}</TableSortLabel>
                    </div>
                  </TableCell>
                }

                return <TableCell align="center" key={col.key}>
                  <TableSortLabel className={classes.bold_text}
                    active={orderBy === col.name}
                    direction={orderBy === col.name ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, col.name)}
                  >{col.name}
                  </TableSortLabel>
                </TableCell>
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRows.map((row) => (
              <TableRow key={row.State}>
                {columns.map((col) => {

                  if (col.key == "StatusText") {
                    return <TableCell align="center"><GetLicenseAppointmentStatusIcon status={row[col.key]} /></TableCell>
                  }

                  if (col.key == "ExpirationDate") {
                    if (row.IsPerpetual == true) {
                      return <TableCell align="center" key={col.key}>Perpetual</TableCell>
                    }
                    else {
                      return <TableCell align="center" key={col.key}>{getFormattedDate(row[col.key], 'MM/DD/YYYY')}</TableCell>
                    }
                  }

                  return <TableCell align="center">{row[col.key]}</TableCell>
                })}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      <LAStatusIconLegendDialog
        title={Strings.LicenseAppointment.LicenseStatusDialogueTitle}
        statusDialogOpen={statusDialogOpen}
        statusList={Object.keys(Strings.LicenseStatusIcon)}
        handleDialogClose={handleDialogClose}
      />

    </>
  );
}
