import { Address, Email, Phone, PreferredItem } from './../reducers/ContactReducer';
import { Contact } from '../reducers/ContactReducer';
import { getAgeFromDate, isNullOrUndefinedOrEmpty, Loaded } from './utilities';
import { composePhoneMetaData } from './phone_email_util';
import { Strings } from '../assets/common/strings';
import { Employer } from '../reducers/employer_reducer';
import { fullNameWithMiddleInitial } from '../assets/common/string_builders';
import { normalizePhone } from './formatting/data_normalizations';
import { findWithFallbacks } from './array_util';
import moment from 'moment';

export const getEmployerPrimaryContact = (employer: Employer) => {
	const primaryContact = employer.contacts && employer.contacts.find(
		contact => contact.employerPrimaryContact
	);

	return primaryContact;
};

export const displayAddress = (address: Address) => {
	const lineOne: string = address.line1 || '';
	const lineTwo: string = address.line2 || '';
	const city: string = address.city || '';
	const state: string = address.state || '';
	const zipCode: string = address.zipCode || '';
	const county: string = address.county || '';

	const streetDisplay: string = !isNullOrUndefinedOrEmpty(lineTwo)
		? lineOne + ' ' + lineTwo
		: lineOne;
	const noStreetAddress: boolean =
		isNullOrUndefinedOrEmpty(lineOne) && isNullOrUndefinedOrEmpty(lineTwo);
	const cityDisplay: string = !isNullOrUndefinedOrEmpty(city)
		? noStreetAddress ? city : ', ' + city
		: ' ';
	const stateDisplay: string = !isNullOrUndefinedOrEmpty(state)
		? ', ' + state
		: ' ';
	const zipCodeDisplay: string = !isNullOrUndefinedOrEmpty(zipCode)
		? ', ' + zipCode
		: ' ';
	const countyDisplay: string = !isNullOrUndefinedOrEmpty(county)
		? ' (' + county + ')'
		: ' ';

	return (streetDisplay + cityDisplay + stateDisplay + zipCodeDisplay + countyDisplay).trim();
};

export const composeAddressFromContact = (contact: Contact) => {
	const mainAddress = getMainAddress({ ...contact });

	const formattedAddress = formatAddress(mainAddress);

	return formattedAddress;
};

const getMainAddress = (contact: Contact) => {
	let preferredAddress: Address | undefined;

	if (contact && contact.addresses && contact.addresses.length) {
		const addresses = [...contact.addresses];
		preferredAddress = addresses.sort(
			(a: Address, b: Address) =>
				moment(b.dateCreated).toDate().getTime() - moment(a.dateCreated).toDate().getTime()
		).find(a => a.isPreferred);
		if (preferredAddress === undefined && addresses.length > 0) {
			preferredAddress = addresses[0];
		}
	}

	return preferredAddress;
};

// TODO: Remove this and use displayAddress() function instead
const formatAddress = (address?: Address) => {
	let line1 = '';
	let line2 = '';
	let zipCode = '';
	let city = '';
	let state = '';
	let county = '';

	if (address) {
		line1 = address.line1 || '';
		line2 = address.line2 || '';

		zipCode = address.zipCode || '';
		city = address.city || '';
		state = address.state || '';
		county = address.county ? '(' + address.county + ')' : '';
	}

	const formattedAddress =
		city && state && zipCode
			? `${line1 + ''}${line2 + ' '}${city + ', '}${state + ' '}${zipCode} ${county}`
			: '';

	return formattedAddress;
};


export const composeNameGenderDobAge = (contact: Contact): string => {
	const name = fullNameWithMiddleInitial(contact, 'Loading...');
	const gender = contact.gender ? ' ' + contact.gender.charAt(0) : '';

	let result = name + gender;
	let statusInd = Strings.Status.Active;
	if (contact.birthDate) {
		const dateOfBirth = moment.utc(contact.birthDate).format('MM/DD/YYYY')
		const age = ' (' + getAgeFromDate(contact.birthDate) + ')';
		result = result.concat(' - ', dateOfBirth, age);
	}
	if (contact.status == Strings.Status.Inactive) {
		statusInd = Strings.Status.Inactive;
		result = result.concat(' - ', statusInd);
	}

	return result;
};

export const composePhoneNumberFromContact = (contact: Contact): string => {
	const phoneMeta = composePhoneMetaData([contact]);
	if (phoneMeta.phones.length == 0) return '';

	const bestPhone = findWithFallbacks(phoneMeta.phones,
		phone => phone.isPreferred,
		phone => phone.type === Strings.PhoneTypes.Mobile,
		phone => phone.type === Strings.PhoneTypes.Home,
		phone => phone.type === Strings.PhoneTypes.Work
	);


	return bestPhone
		? normalizePhone(bestPhone.number)
		: '';
};

export const getContactPreferredFullName = (contact: Contact) => `${contact.preferredName || contact.firstName} ${contact.lastName}`;

export const orderContacts = (contacts: Contact[]) =>
	contacts.sort(
		(memberA: Contact, memberB: Contact) => {
			if (memberA.householdRole < memberB.householdRole) return -1;
			if (memberA.householdRole > memberB.householdRole) return 1;
			else return 0;
		}
	);
export const orderLoadedContacts = (contacts: Loaded<Contact>[]) =>
	orderContacts(contacts.map(c => c.data));

interface ContactInfo {
	phones: Phone[];
	emails: Email[];
	addresses: Address[];
}
const sortDateCreatedDesc = <T extends PreferredItem>(a: T, b: T) =>
	new Date(b.dateCreated)?.getTime() - new Date(a.dateCreated)?.getTime();
const sortIsPreferred = <T extends PreferredItem>(a: T, b: T) =>
	Number(b.isPreferred) - Number(a.isPreferred);
export const getContactInfo = (contact?: Contact): ContactInfo => ({
	// Sort preferred first
	phones: [...(contact?.phones || [])].sort(sortDateCreatedDesc).sort(sortIsPreferred),
	emails: [...(contact?.emails || [])].sort(sortDateCreatedDesc).sort(sortIsPreferred),
	addresses: [...(contact?.addresses || [])].sort(sortDateCreatedDesc).sort(sortIsPreferred),
});
export const generateTypeToDisplay = <T extends PreferredItem>(obj: T) =>
	obj.isPreferred ? `Preferred, ${obj.type}` : obj.type;

export const isContactMedicareEligible = (contact: Contact) => {
	const contactDateDiff = moment().diff(contact.birthDate);
	const contactDiffDuration = moment.duration(contactDateDiff).years();
	const isContactOver65 = contactDiffDuration >= 64.5;

	return isContactOver65;
}

export const hasContactsMedicareEligible = (contacts: Loaded<Contact>[]) => {
	const contactsHasOver65 = contacts.length >= 1 ?
		contacts.find((contact) => {
			return isContactMedicareEligible(contact.data); 
		}) !== undefined
		: false;
	return contactsHasOver65;
}
