import { LisAmountEnum } from '../../reducers/ContactReducer';
// ---------------------------------------------
// Please Maintain Alphabetical Order
// ---------------------------------------------
// Do not add Class Members to use in LoDash Get
// ---------------------------------------------

export module Strings {
    export enum AboutMeFields {
        TimeZone = 'Time Zone',
        SetTimeZone = 'You can now set your time zone in AgentConnect. Please validate your current time zone.',
    }

    export enum AboutMeLabels {
        AboutMe = "About Me",
        Address = "Address ",
        AddressLine1 = "Address Line 1",
        AddressLine2 = "Address Line 2",
        Agent = "Agent",
        Subagent = "SubAgent",
        AgentProfile = "Agent Profile",
        AgentSearchPrompt = "Search for agent by name, city, or zip",
        AppointmentWebsite = "Appointment Website ",
        BusinessEmailAddress = "Business Email Address ",
        BusinessPhone = "Business Phone ",
        ClientConnectSettings = "ClientConnect Settings",
        EmailTitle = "Email Title",
        FirstName = "First Name",
        IconManagement = "Icon Management",
        IconEditor = "Icon Editor",
        LastName = "Last Name",
        LiveTransfer = "Live Transfer",
        Location = "Location",
        MiddleInitial = "MI",
        PersonalPhone = "Personal Phone ",
        PersonalEmailAddress = "Personal Email Address ",
        PreferredName = "Preferred Name",
        Suffix = "Suffix",
        Title = "Title",
    }

    export enum Activity {
        Appointment = 'Appointment',
        Task = 'Task',
        Email = 'E-mail',
        LeadStatusChange = 'Lead Status Change',
        Misc = 'Miscellaneous',
        PhoneCall = 'Phone Call',
        Sms = 'Sms',
        Quoted = 'Quoted',
        FactFinder = 'FactFinder',
        ClientConnect = 'ClientConnect',
        ActivitySeparator = '\n-------'
    }

    export enum ActivityStatus {
        Completed = 'completed',
        Open = 'open',
    }

    export enum Address {
        Add = 'Add',
        Address = 'Address',
        City = 'City',
        Edit = 'Edit',
        Line1 = 'Line 1',
        Line2 = 'Line 2',
        State = 'State',
        ZipCode = 'Zip',
        County = 'County',
    }

    export enum AddressType {
        HomeAddress = 'Home Address',
        Home = 'Home',
        BusinessAddress = 'Business Address',
        Business = 'Business',
        StorefrontAddress = 'Storefront Address',
        Storefront = 'Storefront',
    }

    export enum AdminAccountUpsertForm {
        Id = 'id',
        AgentCode = 'agentCode',
        FirstName = 'firstName',
        LastName = 'lastName',
        PreferredName = 'preferredName',
        ActiveChannel = 'activeChannel',
        Agency = 'agency',
        Timezone = 'timezone',
        CreatedOn = 'createdOn',
        UpdatedOn = 'updatedOn',
    };

    export enum ASearchMode {
        Household = 'household',
        Employer = 'employer',
        None = 'none'
    };

    export enum ASearchFilters {
        SearchNextDays = 'openToDoInNextDaysSearch',
        SearchNextDaysFrom = 'openToDoInNextDaysFrom',
        SearchNextDaysTo = 'openToDoInNextDaysTo',
        SearchPrimaryOnly = 'searchAcrossPrimaryOnly',
        ShowPrimaryOnly = 'showPrimaryResultsOnly',
        AgeMinYear = 'ageMinYear',
        AgeMinMonth = 'ageMinMonth',
        AgeMaxYear = 'ageMaxYear',
        AgeMaxMonth = 'ageMaxMonth',
        HouseholdIncomeMin = 'householdIncomeMin',
        HouseholdIncomeMax = 'householdIncomeMax',
        Unselected = 'Unselected',
        ProcessId = 'processId'
    };

    export enum ASearch {
        NoFilters = '0 filters selected',
        ResultsSelected = ' Results Selected',
        Title = 'Consumer Search',
        SearchTitle = 'Search',
        Deselect = 'Deselect All',
        Select = 'Select All',
        BLeads = 'B-Leads',
        Export = 'Export',
        Reassign = 'Reassign',
        Email = 'Email',
        SelectPreview = 'Select a search result item to preview its linked Household or Employer details...',
        FormerCustomers = 'Former Customers',
        CurrentCustomers = 'Current Customers',
        CompletedLeads = 'Completed Leads',
        ActiveLeads = 'Active Leads'
    };

    export enum Agencies {
        Hmkts = 'HealthMarkets',
        Excelsior = 'Excelsior',
        InSphere = 'InSphere',
        Lighthouse = 'Lighthouse',
    };

    export enum AgencyReferralUrl {
        Hmkts = 'healthmarkets',
        Excelsior = 'excelsiorinsurance',
    };

    export enum AgentRoles {
        SVP = 'Senior Vice President',
        SVPAbbrev = 'SVP',
        TVP = 'Territory Vice President',
        TVPAbbrev = 'TVP',
        SalesLead = 'Sales Leader',
        Agent = 'Agent',
        SponsorAgent = 'Sponsor Agent',
        SubAgent = 'SubAgent',
        ITAdmin = 'AgentConnect-ITAdmin',
    }

    export enum AgentChannelStatus {
        Active = 'Active',
        Suspended = 'Suspended',
        Terminated = 'Terminated',
    };

    export enum AgentCodeResult {
        NoAgentCode = "bad"
    }

    export enum ADRoles {
        BrokerTerminated = 'EIB-Terminated',
        AgentTerminated = 'Agent-Terminated',
        SVP = 'AC_HMIA_SVP',
        TVP = 'AC_HMIA_TVP',
        SalesLeader = 'AC_HMIA_SalesLeader',
        Assistant = 'AC_HMIA_Assistant',
        Operations = 'AC_HMIA_Ops',
        Admin = 'AC_ITAdmin',
        Telesales = 'AC_HMIA_TelesalesAgent',
        SubAgent = 'SubAgent-Active',
    };

    export enum ApiUrls {
        AdminAgents = 'agent/admin',
        AgencyChannels = 'agent/getagencychannels',
        Doc = 'doc',
        DocFavorite = 'doc/favorite',
        Login = 'newLogin',
        Lookups = 'lookup',
        Splunk = 'Splunk',
    };

    export enum AppTitle {
        AgentConnect = 'AgentConnect',
        BrokerConnect = 'BrokerConnect',
    };

    export enum AvatarState {
        Customer = 'Customer',
        Lead = 'Lead',
        Both = 'Both'
    };

    export enum Battle {
        BattleExpired = 'Battle Expired ',
        BattleNotStarted = 'Battle not started',
        BattleRejected = 'Battle Rejected',
        BattleRequest = ' Battle Request',
        Battles = 'Battle',
        Create = 'Create',
        Days = ' Days',
        Declined = 'Declined',
        DeleteConfirmation = 'Are you sure you want to delete this battle?',
        Down = 'Down',
        Draw = 'Draw',
        EndsIn = 'Ends in',
        Expired = 'Expired',
        ExpiresIn = 'Expires in',
        FailedToAcceptOrReject = 'Failed to accept or reject Battle',
        FailedToAddMessage = 'Failed to add Battle message',
        FailedToCreate = 'Failed to create Battle',
        FailedToDelete = 'Failed to delete Battle',
        FailedToDeleteMessage = 'Failed to delete Battle message',
        FailedToGet = 'Failed to get Battle',
        FailedToParse = 'Failed to parse battles information',
        FailedToUpdate = 'Failed to update Battle',
        First = '1st',
        Loss = 'Loss',
        MessageDeleteInfo = 'Each message will be deleted after 30 days',
        NoActiveBattles = 'No Active Battles',
        RejectConfirmation = 'Are you sure you want to reject this battle? If you reject this battle, you cannot accept it at a later time.',
        Rejected = 'Rejected',
        Result = 'Result',
        Second = '2nd',
        StartsIn = 'Starts in',
        SuccessfullyAccepted = 'Battle has been accepted',
        SuccessfullyCreated = 'Your battle has been sent',
        SuccessfullyDeleted = 'Battle deleted',
        SuccessfullyRejected = 'Your battle has been deleted',
        SuccessfullyUpdated = 'Updates have been saved',
        Tie = 'Tie',
        Tied = 'tied',
        TimeRemaining = 'Time Remaining - ',
        TimedOut = 'Timed Out',
        Up = 'Up',
        Win = 'Win',
        You = 'You',
    };

    export enum BattleFormValues {
        START = 'startDate',
        END = 'endDate',
    };

    export enum Boolean {
        False = 'false',
        True = 'true'
    };

    export enum ButtonText {
        Back = 'Back',
        Cancel = 'Cancel',
        ChangeSettings = 'Change Settings',
        Delete = 'Delete',
        Leave = 'Leave',
        Ok = 'OK!',
        Preview = 'Preview',
        Save = 'Save',
        Send = 'Send',
        Stay = 'Stay',
        Undo = 'Undo',
        Next = 'Next',
        Search = 'Search'
    };

    export enum ConsumerSearchFilters {
        Yr = 'yr',
        Mo = 'mo',
        Min = 'Min',
        Max = 'Max',
        Age = 'Age',
        To = 'to',
    };

    export enum CorporateSearchFilers {
        ClientTermLifeYear = 'Clients With Term Life for a Year',
        ClientTermShortLapse = 'Clients With Short Term That are About to Lapse',
    };

    export enum ContactInfoTypes {
        Address = 'address',
        Addresses = 'addresses',
        Business = 'Business',
        CompanyDNC = 'Company DNC',
        Home = 'Home',
        Mailing = 'Mailing',
        Mobile = 'Mobile',
        NationDNC = 'Nation DNC',
        Required = 'Required',
        Phones = 'phones',
        Preferred = 'Preferred',
        ApplyToHousehold = 'Apply update to entire household',
    };

    export enum ClientConnectContactInfoType {
        Assistant = 'Assistant',
        Email = 'Email',
        Mobile = 'Mobile',
        Office = 'Office',
    };

    export enum ContactDedupe {
        Title = 'Duplicate Person',
        SelectContactStep = 'SelectContact',
        SelectHouseholdStep = 'SelectHousehold'
    };

    export enum ContactInfoModes {
        Email = 'Email',
        Phone = 'Phone',
    }

    export enum ContentEditor {
        ID = "RTE_Tinymce",
        Selector = 'textarea',
        ContentCSS = '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        PasteStyle = 'all',
        ContextMenu = 'copy cut paste | link image inserttable | cell row column deletetable',
        ContentStyle = '.mce-content-body table, .mce-content-body table td, .mce-content-body table th {border: none!important;}',
        PluginA = 'advlist autolink lists link image charmap  preview textcolor paste',
        PluginB = 'fullscreen table',
        PluginC = 'insertdatetime media help',
        PluginD = 'contextmenu',
        Toolbar = 'undo redo | formatselect | fontselect | fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image charmap link table | preview | addVariables ',
        Button = 'Add Variables',
    };

    export enum Context {
        Activity = 'Activity',
        Application = 'Application',
        Contact = 'Contact',
        Cordova_Db = 'Cordova Database',
        Employer = 'Employer',
        Email = 'Email',
        Household = 'Household',
        Lead = 'Lead',
        Note = 'Note',
        Policy = 'Policy',
    };

    export enum Cordova {
        ErrorStoringUsername = 'Error storing username in sql cipher',
        SecureStoragePasswordKey = 'ac_password',
        SecureStorageUsernameKey = 'ac_username',
    };

    export enum CSS {
        Auto = 'auto',
        Black = '#000',
        BottomStart = 'bottom-start',
        Center = 'center',
        FlexEnd = 'flex-end',
        Pointer = 'pointer',
        Sticky = 'sticky',
        Right = 'right',
        Hidden = 'hidden',
        Scroll = 'scroll',
        Initial = 'initial',
        Bold = 'bold',
        Left = 'left',
        Block = 'block',
        White = 'white',
        Caption = "caption",
    };

    export enum Disclaimer {
        Dnc = 'You are solely responsible for compliance with the requirements of the TCPA, federal/state Do-Not-Call lists and CAN-SPAM, including appropriate consent, prior to communicating with this prospect. We recommend you consult your own legal counsel to understand your responsibilities under the law.',
    };

    export enum DisplayState {
        DisplayForm, // Visible - waiting for user interaction
        DisplayPin, // Visible
        Hidden,
        Loading, // Visible - waiting for API
        VersionOutOfDate,
        CheckingForUpdates = 'Checking for updates...',
    };

    export enum Household {
        FailedMergeHouseholds = 'Failed to merge households',
        SuccessMergeHouseholds = 'Households merged'
    }
    export enum Http {
        Get = 'GET',
        Post = 'POST',
        Put = 'PUT',
        Delete = 'DELETE'
    };

    export enum HttpMode {
        Navigate = 'navigate',
        SameOrigin = 'same-origin',
        NoCors = 'no-cors',
        Cors = 'cors',
    };

    export enum DateFormat {
        MDYYYYHMMA = 'M-D-YYYY, h:mm a',
        Default = 'mm/dd/yyyy',

    };

    export enum DateSelector {
        CustomDateRange = 'Custom Date Range',
        Last7Days = 'Last 7 Days',
        Last30Days = 'Last 30 Days',
        Last90Days = 'Last 90 Days',
        MonthToDate = 'Month-to-Date',
        QuarterToDate = 'Quarter-to-Date',
        YearToDate = 'Year-to-Date',
        LastMonth = 'Last Month',
        LastQuarter = 'Last Quarter',
        LastYear = 'Last Year',
    };

    export enum DispositionReason {
        CoverageReviewNoChange = 'Coverage Review - No Change',
        QuotedCallbackRequested = 'Quoted - Callback Requested',
        QuotedAppointmentSet = 'Quoted - Appointment Set',
        AppointmentHeldNoSale = 'Appointment Held - No Sale',
        CancelledNoSale = 'Cancelled - No Sale',
    }

    export enum DeviceOs {
        Web = 'web',
        Android = 'android',
        Ios = 'ios',
    };

    export enum DncReason {
        None = "None",
        RecentlyCalled = "Recently Called",
        TelemarketingRules = "Telemarketing Rules",
        CompanyDNC = "Company DNC",
        NationalDNC = "National DNC",
        MissingZip = "Missing Zip Code",
        MissingPhone = "Missing Phone Number",
        MissingTimeZone = "Missing Time Zone"
    };

    export enum DocumentFilterLabels {
        Search = 'Search',
        AllCategories = 'All Categories',
        AllLinesOfBusiness = 'All Lines of Business',
    };

    export enum DocumentSubtitleLabels {
        Updated = 'Updated:',
    };

    export enum DocumentFileExtensions {
        PowerPointX = 'pptx',
        PowerPoint = 'ppt',
        ExcelX = 'xlsx',
        Excel = 'xls',
        WordX = 'docx',
        Word = 'doc',
        Gif = 'gif',
        Jpg = 'jpg',
        Jpeg = 'jpeg',
        Png = 'png',
        Pdf = 'pdf',
        Svg = 'svg'
    };

    export enum EmailGroup {
        working = 'Working',
        active = 'Active',
        lost = 'Lost',
        terminated = 'Terminated',
    };

    export enum TemplateType {
        Corporate = 'Corporate',
        Agent = 'Agent'
    };

    export enum Event {
        KeyDown = 'keydown'
    };

    export enum Filter {
        All = 'All',
        AgeMin = 'Age Min',
        AgeMax = 'Age Max',
        MultiSelect = 'MultiSelect',
        Income = 'Annual Income Range',
    };

    export enum FactFinderPrescriptionLookup {
        addDrug = 'Add Drug',
        maxDrugAmount = 'A maximum of 30 drugs can be added',
        prescriptionLookup = 'Prescription Lookup',
        savePrescriptions = 'Save Prescription(s)',
        searchingForDrugs = 'Searching for drug...',
        selectedDrugs = 'Selected Drugs',
    }

    export enum FactFinderProviderLookup {
        doctorLookup = 'Doctor Lookup',
        invalidZipCodeError = 'Invalid ZipCode',
        lengthZipCodeError = 'Must be 5 characters long',
        maxDoctorAmount = 'A maximum of 10 doctors can be added',
        noDoctorFound = 'We\'re sorry we\'re unable to find that doctor. Please try again.',
        saveDoctors = 'Save Doctor(s)',
        searchingForProvider = 'Searching for provider...',
        selectedDoctors = 'Selected Doctors',
    }

    export enum Form {
        Required = 'Required'
    };

    export enum GraphDataFilter {
        LineOfBusiness = 'Line of Business',
        LookUpAgent = 'Look Up Agent',
        FindAgent = 'Find Agent',
        View = 'View',
        Search = 'Search',
    }

    export enum GraphDataFilterForm {
        LineOfBusiness = 'lineOfBusiness',
        ComparePrevious = 'comparePrevious',
    };

    export enum HeaderTitles {
        TemplateManager = 'Manage Email Templates'
    };

    export enum HttpOptions {
        ContentJson = 'application/json',
        ContentType = 'Content-Type',
    };

    export enum Icons {

        Contacts = 'contacts',
        ContactMail = 'contact_mail',
        KbArrowLeft = 'keyboard_arrow_left',
        Language = 'language',
        Merge = 'merge_type',
        Person = 'person',
        Unarchive = 'unarchive',
        PhoneCall = 'local_phone',
    };

    export enum Keys {
        Template = 'Template: ',

    };

    export enum Languages {
        English = 'English',
        Spanish = 'Spanish'
    };

    export enum LeadCallsAttempted {
        NoneToday = "None Today",
        NoneInLastSeven = "None in the Last 7 Days",
        NoneInLastThirty = "None in the Last 30 Days",
        All = "All",
        CallsZero = "0",
        CallsOne = "1",
        CallsTwo = "2",
        CallsThreePlus = "3 +"
    }

    export enum LeadDialogType {
        Info = 'Info',
        Search = 'Search'
    } ''

    export enum LeadFieldNames {
        LineOfBusiness = "leadLOB",
        CreatedDateSearch = "leadCreatedDateSearch",
        CreatedDate = "leadCreatedDate",
        ContactedDateSearch = "leadContactedDateSearch",
        ContactedDate = "leadContactedDate",
        StatusCode = "leadStatusCode",
        StatusReason = "leadStatusReason",
        Callable = "callable",
        Type = "leadType",
        Vendor = "leadVendor",
        Campaign = "leadCampaign",
        Fund = 'leadFund',
        LmsId = "leadLMSID",
        QuoteId = "leadQuoteID",
        OpenToDoInNextDaysSearch = "openToDoInNextDaysSearch",
        OpenToDoInNextDays = "openToDoInNextDays",
        ManuallyEntered = "leadManuallyEntered",
        AgeMinMonth = "ageMinMonth",
        AgeMinYear = "ageMinYear",
        AgeMaxMonth = "ageMaxMonth",
        AgeMaxYear = "ageMaxYear",
        NumFamilyMembersMin = "numFamilyMembersMin",
        NumFamilyMembersMax = "numFamilyMembersMax",
        HouseholdIncomeMin = "householdIncomeMin",
        HouseholdIncomeMax = "householdIncomeMax",
        CallAttempts = "callAttempts",
        LeadTags = "leadTags",
    };

    export enum LeadSearch {
        FiltersSelected = ' filters selected',
        LeadSearch = 'Lead Search',
        Search = 'Search'
    };

    export enum LeadSelector {
        Lead = 'Lead',
        LeadCreated = 'Lead Created',
        LeadModified = 'Lead Modified',
        LeadMustBeSelected = 'A Lead must be selected',
        NoLeadForEntity = 'There are no Leads for this Household/Employer',
        SelectTheLead = 'Select the Lead related to this Application'
    };

    export enum LeadField {
        LeadField = 'Lead Field',
        Campaign = 'Campaign',
        DateType = 'Date Type',
        Rating = 'Rating',
        Status = 'Status',
        StatusCode = 'Status Code',
        StatusGroup = 'Status Group',
        Vendor = 'Vendor',
        Sort = 'Sort',
        LineOfBusiness = 'Line of Business',
        InputSource = 'Input Source'
    }

    export enum LeadType {
        Emailed = "Emailed",
        Texted = "Texted",
        Called = "Called",
        NoAnswer = "No Answer",
        NoMessage = "No Message",
        LeftMessage = "Left Message",
        Contacted = "Contacted",
        Complete = "Complete",
    }

    export enum LineOfBusiness {
        LowerCommericalHealth = 'commercial health',
        LongTermCare = 'Long Term Care',
        Medicaid = 'Medicaid',
        MedicareSupp = 'Medicare Supplement',
        Annuity = 'Annuity',
        Association = 'Association'
    };

    export enum LineOfBusinessAbbreviations {
        commercialHealth = 'H',
        LongTermCare = 'LT',
        Life = 'L',
        Medicare = 'M',
        Medicaid = "MD",
        MedicareSupp = "MS",
        Annuity = 'A',
        Association = 'AS'
    };

    export enum LinkVerbiage {
        Default = 'View Link',
        Battle = 'View Battle',
        Battles = 'View Battles',
        Household = 'View Household',
        Employer = 'View Employer',
        Leads = 'View Leads',
    };

    export enum LoginForm {
        LabelUsername = 'Username',
        LabelPassword = 'Password',
        Response_403 = 'Invalid username or password',
        Response_Bad = 'Unable to login',
        AgencyPermission = 'Forbidden',
        ValidateRequiredUsername = 'Username is required',
        ValidateRequiredPassword = 'Password is required',
        PasswordExpired = 'Password Expired',
        PasswordExpiredOpenLink = 'Password Expired - Redirecting to reset form',
        LoginExpired = 'Login Expired',
    };

    export enum LoginState {
        Initial = 'Initial',
        CheckingAuth = 'Checking Authentication',

        PreparingExperience = 'Preparing experience...',
        CancelingLogin = 'Canceling Login...',
        CheckingForUpdates = 'Checking for updates...',
        VersionOutOfDate = 'This version is out of date.',

        Authenticated = 'Authenticated',
        LogoutPending = 'Logging out..',

        DisplayForm = 'Display form',
    };

    export enum Log {
        Alert = 'alert',
        Crit = 'crit',
        Debug = 'debug',
        Emerg = 'emerg',
        Error = 'error',
        Info = 'info',
        Log = 'log',
        Notice = 'notice',
        Warn = 'warn',
    };

    export enum MedicareInfoFieldLabels {
        Prescription = 'Prescription',
        Dosage = 'Dosage',
        Provider = 'Provider',
        PhoneNumber = 'Phone Number',
        ZipCode = 'Zip Code',
        Address = 'Address',
        City = 'City',
        State = 'State',
        Pharmacy = 'Pharmacy',
        Pharmacies = 'Pharmacies',
        PartAEffectiveDate = 'Part A Effective Date',
        PartBEffectiveDate = 'Part B Effective Date',
        MedicareNumber = 'MedicareNumber',
        LowIncomeSubsidy = 'Low Income Subsidy',
    }

    export enum LisDisplay {
        fixedCoverage1 = 'I pay $1.25 - $3.70 for covered drugs.',
        fixedCoverage2 = 'I pay $3.35 - $8.35 for covered drugs.',
        percentageCoverage0Percent = '0%',
        percentageCoverage25Percent = '25%',
        percentageCoverage50Percent = '50%',
        percentageCoverage75Percent = '75%',
        percentageCoverageIDK = 'I don\'t know',
        noHelp = 'I don’t get any extra help.',
        dontKnow = 'I don’t know.'
    }

    export const LisMap = {
        [LisAmountEnum.LTE100]: LisDisplay.fixedCoverage1,
        [LisAmountEnum.GT100]: LisDisplay.fixedCoverage2,
        [LisAmountEnum.BT136_149_0]: LisDisplay.percentageCoverage0Percent,
        [LisAmountEnum.BT136_149_25]: LisDisplay.percentageCoverage25Percent,
        [LisAmountEnum.BT136_149_50]: LisDisplay.percentageCoverage50Percent,
        [LisAmountEnum.BT136_149_75]: LisDisplay.percentageCoverage75Percent,
        [LisAmountEnum.LIS_PAYS_IDK]: LisDisplay.percentageCoverageIDK,
        [LisAmountEnum.LIS_NONE]: LisDisplay.noHelp,
        [LisAmountEnum.LIS_IDK]: LisDisplay.dontKnow,
    };

    export enum MilestoneStatus {
        InProgress = 'INPROGRESS',
        Ineligible = 'INELIGIBLE',
        Missed = 'MISSED',
    }

    export enum MoreMenu {
        ClientConnect = 'Send ClientConnect',
        CopyPhoneNumber = 'Copy Phone Number',
        Delete = 'Delete',
        Details = 'Details',
        Edit = 'Edit',
        EditPerson = 'Edit Person',
        Help = 'Help',
        MarkAsDependent = 'Mark as Dependent',
        MarkAsPrimary = 'Mark as Primary',
        MarkAsSpouse = 'Mark as Spouse/Partner',
        MoveToNewHousehold = 'Move to New Household',
        Preview = 'Preview',
        Print = 'Print',
        Quote = 'Quote',
        Reassign = 'Reassign',
        RemoveMember = 'Remove Member',
        UnlinkFromEmployer = 'Unlink from Employer',
    };

    export enum MUI {
        Flat = 'flat',
        Raised = 'raised'
    };

    export enum Navigation {
        ActivityId = ':activity_id',
        QuoteActivityId = ':quoteactivity_id',
        AgentCode = ':agentcode',
        ApplicationId = ':applicationID',
        BattleId = ':battle_id',
        CategorySelector = ':categorySelector',
        ContactId = ':contactID',
        EditMode = ':isEditMode',
        EmailHistoryId = ':emailHistoryId?',
        EmployerId = ':employerID',
        FollowUp = ':followup',
        From = ':from',
        FromAdvSearch = ':fromAdvSearch',
        HouseholdId = ':householdID',
        LeadId = ':leadID',
        Management = 'Management',
        NotificationId = ':notificationId',
        PolicyId = ':policyID',
        ProductId = ':productID',
        Rematch = ':rematch',
        Reschedule = ':reschedule',
        TemplateId = ':templateId',
        TemplatePageState = ':templateState',
        UserId = ':userID',
        PodcastId = ':podcast_id'
    };

    // Operation
    export enum Op {
        Updated = 'Updated',
    };

    export enum PinAuth {
        StorePin = 'Pin stored successfully into db',
        StorePinError = 'Error saving pin to SQL Cipher',
        GetPinError = 'Failed to retrieve pin from SQL Cipher',
        Cleared = 'Pin cleared successfully',
        ClearedError = 'Error clearing pin from SQL Cipher',
        Settings = 'Pin settings saved successfully in db',
        SettingsError = 'Error saving pin settings to SQL Cipher',
        LoadError = 'Error loading pin settings',
        LockError = 'Failed to lock screen to portrait for pin',
        UnlockError = 'Failed to unlock screen from portrait for pin',
    };

    export enum Notification {
        FailedToCreate = 'Failed to create Notification',
        FailedToDelete = 'Failed to delete Notification',
        FailedToGet = 'Failed to get Notification',
        FailedToUpdate = 'Failed to update Notification'
    };

    export enum NotificationStatus {
        Unset = 'unset',
        Succeeded = 'succeeded',
        Failed = 'failed',
    };

    export enum Permissions {
        CorporateTemplateDelete = 'corporateTemplatesDelete',
        CorporateTemplateUpdate = 'corporateTemplatesUpdate',
        CorporateTemplateCreate = 'corporateTemplatesCreate',
        Administrator = 'admin',
        BulkEmail = 'bulkEmail',
    };

    export enum PersonInfo {
        FirstName = 'First Name',
        LastName = 'Last Name',
        DOB = 'Date of Birth',
        Title = 'Job Title',
        Occupation = 'Occupation',
        PreferredName = 'Preferred Name',
        MiddleInitial = 'M.I.',
        MiddleName = 'Middle Name',
        Suffix = 'Suffix',
        LastFourSSN = 'Last Four of SSN',
        Height = 'Height',
        Weight = 'Weight',
        isTobaccoUser = 'Tobacco User?',
        Status = 'Person Status',
        isStudent = 'Student?',
        isSelfEmployed = 'Self-Employed?',
        AnnualIncome = 'Annual Income',
        Language = 'Language Preference',
        MaritalStatus = 'Marital Status',
        ClientType = 'Client Type',
        Salutation = 'Salutation',
        Gender = 'Gender',
        LastUnemploymentYear = 'Last Unemployment Year',
    };

    export enum Phones {
        Extension = 'Extension',
        Edit = 'Edit Phone',
        Number = 'Phone Number',
    };

    export enum PhoneTypes {
        Home = 'Home',
        Mobile = 'Mobile',
        Business = 'Business',
        Alternate = 'Alternate',
        Work = 'Work'
    }

    export enum Status {
        Active = 'Active',
        Inactive = 'Inactive',
    }

    export enum Podcast {
        Podcast = 'Podcast',

    }

    export enum ProductDateOptions {
        ApplicationDate = 'Application Date',
        EffectiveDate = 'Effective Date',
        TerminatedDate = 'Terminated Date',
        IssuedDate = 'Issued Date',
        OutstandingDocumentDueDate = 'Outstanding Document Due Date',
    }

    export enum ProductGeneralEditOptions {
        ShowMore = 'Show More',
        General = 'General',
        LineOfBusiness = 'Line of Business',
        Carrier = 'Carrier',
        CarrierName = 'Carrier Name',
        ProductType = 'Product Type',
        Product = 'Product',
        MetalLevel = 'Metal Level',
        ApplicationDate = 'Application Date',
        ApplicationStatus = 'Application Status',
        EffectiveDate = 'Effective Date',
        StatusReason = 'Status Reason',
        PolicyEndDate = 'Policy End Date',
        OutstandingDocument = 'Outstanding Document',
        OutstandingDocumentDueDate = 'Outstanding Document Due Date',
    }

    export enum ProductInsuredEditOptions {
        Insureds = 'Insureds',
        SelectTheInsureesForTheApplication = 'Select the insurees for the application',
    }

    export enum ProductPremiumInfoEditOptions {
        PremiumInfo = 'Premium Info',
        AnnualPremium = 'Annual Premium',
        MonthlyPremium = 'Monthly Premium',
        AnnualFederalSubsidy = 'Annual Federal Subsidy',
        MonthlyFederalSubsidy = 'Monthly Federal Subsidy',
        AnnualNetCost = 'Annual Net Cost',
        MonthlyNetCost = 'Monthly Net Cost',
    }

    export enum ProductPolicyAmountsEditCard {
        PolicyAmounts = 'Policy Amounts',
        IndividualDeductible = 'Individual Deductible',
        IndividualMaxOutOfPocket = 'Individual Max Out of Pocket',
        PerPersonDeductible = 'Per Person Deductible',
        PerPersonMaxOutOfPocket = 'Per Person Max Out of Pocket',
        FamilyDeductible = 'Family Deductible',
        FamilyMaxOutOfPocket = 'Family Max Out of Pocket',
        FaceAmount = 'Face Amount',
        BenefitAmount = 'Benefit Amount',
        DrugDeductible = 'Drug Deductible',
        AnnualDrugCost = 'Annual Drug Cost',
    }

    export enum ProductAdminEditCard {
        Admin = 'Admin',
        ApplicationNumber = 'Application Number',
        CarrierProducerCode = 'Carrier Producer Code',
        Sold = 'Sold',
        OnExchange = 'On Exchange',
        OffExchange = 'Off Exchange',
        EnrollmentMethod = 'Enrollment Method',
        Electronic = 'Electronic',
        Paper = 'Paper',
    }

    export enum ProductList {
        commercialHealth = 'commercial health',
        employer = 'employer',
        household = 'household',
        policy = 'policy'
    };

    export enum PolicySearch {
        All = 'All',
    }

    export enum ProductStatus {
        Pending = 'Pending',
        Active = 'Active',
        Terminated = 'Terminated',
    }

    export enum ProductStatusReason {
        PendingEnrollment = 'Pending Enrollment',
        Enrolled = 'Enrolled',
    }

    export enum ProductMatch {
        Unmatched = "Unmatched",
        Matched = 'Matched',
    }

    export enum ProductCountType {
        Customer = "Cust.",
        Products = "Products",
        ProductsSold = "Products Sold",
        UnmatchedPolicies = "Unmatched Policies",
    }

    export enum PolicyOrApplicationValue {
        Policy = 'policy',
        Application = 'application',
        Both = 'both'
    }

    export enum PushNotification {
        Failed = 'Failed receiving push notification from server',
    };

    export enum ReShop {
        Accept = 'Accept',
        Carrier = 'Carrier',
        CurrentPlan = 'Current Plan',
        Decision = 'Decision',
        Dental = 'Dental',
        Exclude = 'Exclude',
        ExpandDetails = 'Expand Details',
        FitScore = 'FitScore',
        FitScoreNeeded = 'FitScore Needed',
        FitScoreTrademarked = 'FitScore\u2122 ',
        Health = 'Health',
        Keep = 'Keep',
        MedicarePrimary = 'Medicare Advantage for Primary',
        MedicareSpouse = 'Medicare Advantage for Spouse',
        NoDecisions = 'No Decisions',
        NoPlan = 'No Plan',
        NoSubsidyApplied = 'No subsidy applied',
        Plan = 'Plan',
        PlanID = 'Plan ID',
        RecommendedPlan = 'Recommended Plan',
        Reject = 'Reject',
        SubsidyApplied = 'Subsidy applied',
        Switch = 'Switch',
        Vision = 'Vision',
    }

    export enum ResourceCenterResourcePaths {
        Reports = '/Reports/pages/Reports.aspx?ReportPath={0}&ReportName={1}&Reportid={2}&List={3}'
    }

    export enum ResourceCenterReports {
        CommissionSummary = 'Commission Summary',
        FinancialReportsList = 'Financial Reports',
    }

    export enum RiseDataLabels {
        FirstName = 'primaryFirst',
        MiddleName = 'primaryMiddle',
        LastName = 'primaryLast',
        PhoneNumber = 'primaryPhone#',
        PhoneType = 'primaryPhoneType#',
        Email = 'primaryEmail#',
        ZipCode = 'primaryZipCode',
        County = 'primaryCounty',
        DateOfBirth = 'primaryDob'
    };

    export enum SideBarMenu {
        Home = 'Home',
        Notifications = 'Notifications',
        ConsumerSearch = 'Consumer Search',
        LeadSearch = 'Lead Search',
        PolicySearch = 'Policy Search',
        HR = 'HR',
        LicenseAppointment = 'Licenses & Appointments',
        ToDoList = 'To-Do List',
        CRM = 'CRM',
        Leads = 'Leads',
        FactFinder = 'Fact Finder',
        QuoteConnect = 'QuoteConnect',
        ClientConnect = 'ClientConnect',
        ReportBuilder = 'My Reports',
        TeamView = 'Team View',
        Performance = 'Performance',
        Battles = 'Battles',
        Finances = 'Finances',
        Training = 'Training',
        Documents = 'Documents',
        Tools = 'Tools',
        News = 'News',
        AgentSearch = 'Agent Search',
        Admin = 'Admin',
        Import = 'Import',
        MyProfile = 'My Profile',
        Settings = 'Settings',
        HelpAndSupport = 'Help & Support',
        Logout = 'Logout',
        Podcasts = 'Podcasts',
        connectureDRX = 'Connecture DRX',
        Connecture = 'Connecture',
    }
    export enum Sizing {
        Small = 'small'
    };

    export enum SelectContact {
        PreferredNumbers = 'Preferred Numbers',
        OtherNumbers = 'Other Numbers',
        PreferredEmails = 'Preferred Emails',
        SecondaryEmails = 'Secondary Emails',
    };

    export enum Settings {
        Calling = 'Calling',
        CallingInfo = 'Enable making phone calls from PC/Laptop. Note: you may have to install a 3rd party tool for this to function properly.',
        FailedToUpdate = 'Failed to update settings',
        SavingSettings = 'Saving Settings',
        Texting = 'Texting',
        TextingInfo = 'Enable sending text messages from PC/Laptop. Note: you may have to install a 3rd party tool for this to function properly.',
        WebCallSettings = 'Web Call/Text Settings',
        ClickToCallSettings = 'Caller ID Settings',
    }

    export enum SmsSequence {
        TemplateLanguage = 'SMS Template Language',
    }

    export enum SnackbarTypes {
        Failed,
        Information,
        Success
    };

    export enum SortOrder {
        Ascending = 'asc',
        Descending = 'desc',
    };

    export enum SortingOrder {
        Newest_Created = 'Newest to Oldest by Created Date',
        Oldest_Created = 'Oldest to Newest by Created Date',
        Newest_Modified = 'Newest to Oldest by Modified Date',
        Oldest_Modified = 'Oldest to Newest by Modified Date'
    };

    export enum Status {
        All = 'All',
        AgentDeparture = 'Agent Departure',
        AgentInactivity = 'Agent Inactivity',
        ApplicationInProgress = 'Application In Progress',
        AppointmentSet = 'Appointment Set',
        CallbackRequested = 'Callback Requested',
        CalledLeftMessage = 'Called - Left Message',
        CalledNoAnswer = 'Called - No Answer',
        CalledNoMessage = 'Called - No Message',
        Cost = 'Cost',
        CoveredByCompetition = 'Covered by Competitor',
        CoveredBySpouse = 'Covered by Spouse',
        CustomerWillCallBack = 'Customer Will Call Back',
        Deceased = 'Deceased',
        DisconnectedPhone = 'Disconnected Phone',
        EmailedAwaitingResponse = 'Emailed - Awaiting Response',
        GroupInsurance = 'Group Insurance',
        InsufficientCoverage = 'Insufficient Coverage',
        InsuranceNotDesired = 'Insurance Not Desired',
        LanguageBarrier = 'Language Barrier (formerly "Does not speak English")',
        Medicaid = 'Medicaid',
        NeverInquired = 'Never Inquired',
        NoAnswer = 'No Answer',
        NotContacted = 'Not Contacted',
        Occupation = 'Occupation',
        Other = 'Other',
        OutOfArea = 'Out of Area',
        OverAge = 'Over Age',
        Quoted = 'Quoted',
        TextedAwaitingAnswer = 'Texted - Awaiting Response',
        UnderAge = 'Under Age',
        Uninsurable = 'Uninsurable',
        WorkingWithAgent = 'Working With Agent',
        WrongNumber = 'Wrong Number',
        Attempted = 'Attempted',
        Called = 'Called',
        Complete = 'Complete',
        Contacted = 'Contacted',
        DealsInProgress = 'Deals in Progress',
        NoShowCallback = 'No Show - Callback',
        RescheduleAppointment = 'Reschedule Appointment',
        Sale = 'Won',
        NoSale = 'Lost',
        Open = 'Open',
        Merged = 'Merged',
        New = 'New',
        SkipNoSaleFollowUp = 'No Sale - Skip Follow Up',
    }

    export enum Support {
        DefaultEmailSubject = "AgentConnect Issue",
    }


    export enum TinyMCE {
        ContentCSS = '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        ContentStyle = '.mce-content-body table, .mce-content-body table td, .mce-content-body table th {border: none!important;}',
        ContextMenu = 'copy',
        ReadOnly = 'readonly',
        Design = 'design',
        Plugins = 'contextmenu',
        Selector = 'textarea',
        Toolbar = ' ',
    };

    export enum Theming {
        Primary = 'primary',
        Secondary = 'secondary'
    };

    export enum TeamViewAPI {
        ACTIVE_SL_ROUTE = 'TeamView/GetNumberOfActiveSalesleaders',
        ACTIVITY = 'TeamView/GetActivityData',
        LEADS = 'TeamView/GetLeadData',
        PRODUCTION = 'TeamView/GetProductionData',
        QUALITY = 'TeamView/GetQualityData',
        DOWNLINE_API = 'ByDownline',
        YDTAV = 'TeamView/GetYTDAV',
        LEADS_CHARTS = 'TeamView/GetLeadDispositionConversion',
        TWENTY_SIXTY_TWENTY = 'TeamView/Get206020Rankings',
        NEW_AGENT_CONVERSION = 'TeamView/GetNewAgentConversion',
        WRITING_AGENTS = 'TeamView/GetWritingAgentStats',
        SUMMARY = 'TeamView/GetSummary',
        SUREBRIDGE_PERSISTENCY = 'TeamView/GetPersistencyStats',
        PROFESSIONAL_AGENTS = 'TeamView/GetProfessionalAgentStats',
        YDTAV_ROUTE = 'GetYTDAV',
        LEADS_CHARTS_ROUTE = 'GetLeadDispositionConversion',
        SUREBRIDGE_PERSISTENCY_ROUTE = 'GetPersistencyStats',
        SUREBRIDGE_PERSISTENCY_AVG_RANK_ROUTE = 'GetGetPersistencyAvgAndRank',
        TEAMVIEW_API = 'TeamView',
        PERFORMANCE_API = 'SalesPerformance'
    }

    export enum TeamView {
        TEAMVIEW = 'Team View',
        LOADING = 'Loading...',
        NO_DATA_FOUND = 'No Data Found',
        SHOW_LESS = 'Show Less',
        SHOW_MORE = 'Show More',
        TERMINATED = 'Terminated',
        ACTIVITIES = 'Team - Lead Activities',
        ACTIVITY_CARD = 'activityCard',
        LEAD_PERFORMANCE = 'Team - Lead Conversion',
        LEAD_CARD = 'leadCard',
        PERFORMANCE = 'Performance',
        PERFORMANCE_TAB = 'Performance Reports',
        PERFORMANCE_ACTIVITIES = 'performanceActivities',
        PERFORMANCE_LEADS = 'performanceLeads',
        PERFORMANCE_PRODUCTION = 'performanceProduction',
        PERFORMANCE_QUALITY = 'performanceQuality',
        PRODUCTION = 'Team - Sales Production',
        PRODUCTION_CARD = 'productionCard',
        QUALITY = 'Team - Agent Quality',
        QUALITY_CARD = 'qualityCard',
        POSITIVE = '▲',
        NEGATIVE = '▼',
        NEW_AGENT_CONVERSION_CARD = 'New Agent Conversion (Last 12 months)',
        NEW_AGENT_Bottom_Text = '(based on when metric achieved, not when new agent joined HM)',
        NEW_LEAD_CARD = 'Leads (Last 4 Weeks) as of',
        NOCHANGE = '‒',
        LEADS_TAB = 'Leads Reports',
        LEADS_TAB_MOBILE = 'Leads',
        PROFESSIONAL_AGENTS = 'Professional Agents (R12 FYCW \u2265 50K)',
        RECRUITING_TAB = 'Field Force Reports',
        RECRUITING_TAB_MOBILE = 'Field Force',
        SALES_TAB = 'Sales Stats Reports',
        SALES_TAB_MOBILE = 'Sales Stats',
        SUMMARY_TAB = 'Summary Report',
        SUMMARY_TAB_MOBILE = 'Summary',
        SUREBRIDGE_PERSISTENCY = 'SureBridge Persistency (AV in 000\'s)',
        TWENTY_SIXTY_TWENTY_CARD = '20 / 60 / 20 Agents (Last 12 months)',
        WRITING_AGENTS = 'Writing Agents',
    }

    export enum TeamViewDelta {
        POSTIVEDELTA = 3,
        NEGATIVEDELTA = 2,
        NOCHANGEDELTA = 1,
        NODELTA = 0,
    }

    export enum TemplateActions {
        FactoryName = 'Templates',
        GetTemplateById = 'GET_TEMPLATE_BY_ID',
        GetTemplateWithSubstitutionsById = 'GET_TEMPLATE_SUBS_BY_ID',
        GetAgentTemplates = 'GET_AGENT_TEMPLATES',
        GetCorporateTemplates = 'GET_CORPORATE_TEMPLATES',
        DeleteTemplate = 'DELETE_TEMPLATE',
        SendBulk = 'SEND_BULK_EMAIL',
        PostTemplate = 'POST_TEMPLATE',
        GetSubs = 'GET_TEMPLATE_SUBSTITUTIONS',
        StoreTab = 'STORE_TAB',
        StoreTemplateFilters = 'STORE_TEMPLATE_FILTERS',
        ClearFilters = 'CLEAR_FILTERS',
        StoreEdit = 'STORE_EDITING_TEMPLATE',
        ClearEdit = 'CLEAR_EDITING_TEMPLATE',
        ClearStore = 'CLEAR_TEMPLATE_STORE',
        SetTablePage = 'SET_TABLE_PAGE',
        SetRowsPerPage = 'SET_ROWS_PER_PAGE',

    };

    export enum TemplateColumns {
        Title = 'Title',
        DraftStatus = 'Status',
        LinesOfBusiness = 'Lines of Business',
        Language = 'Language',
        UpdatedOn = 'Updated On',
        TemplateType = 'Template Type',
        TemplateEvent = 'Template Event',
    };

    export enum TemplateDefaults {
        Description = 'Description not supplied.',
        Email = 'John.Doe@Email.com',
        From = 'From: ',
        LabelSubject = 'Subject: ',
        LobHealth = 'Health',
        HtmlContent = '<p>No template html content was supplied.</p>',
        Subject = 'Subject not supplied.',
        Title = 'No title supplied',
        To = 'To: ',
        SmsTemplatePreview = 'SMS Template Preview',
        SmsTemplateContentEditor = 'SMS Template Content Editor',
        EmailTemplatePreview = 'Email Template Preview',
    };

    export enum TemplateManagement {
        AdminOnly = 'Only Corporate Administrators are allowed to edit Corporate Templates.',
        Agent = 'Agent',
        AtHMKTS = '@HealthMarkets.com',
        AtEIB = '@ExcelsiorInsurance.com',
        Bulk = 'Bulk',
        CancelNoSave = 'Are you sure you want to cancel without saving?',
        Corporate = 'Corporate',
        ConfirmDelete = 'Are you sure you want to delete ',
        Content = 'Content',
        DetailsTitle = 'Email Template Details',
        EditorTitle = 'Email Template Content Editor',
        FailedSave = 'Failed to saved template due to empty required fields.',
        History = 'History',
        Management = 'Management',
        IncompleteFields = 'Please complete all required fields.',
        InUse = ' is currently in use by Email Automations.',
        NewTemplate = 'newTemplate',
        RemoveBeforeEdit = 'Please remove it before editing or deleting this template.',
        Sending = 'Sending',
        ThisTemplate = 'This template',
        ThisTemplateLower = ' this template',
        TemplateSaveSucces = 'Successfully saved the template.',
        TemplateWasDeleted = 'The template you are looking for has been deleted.',
        SelectTemplate = "Select Template",
    };

    export enum TemplateSaga {
        ApiBase = 'EmailTemplate/',
        GetAgentTemplates = 'getEmailTemplatesForAgent',
        GetCorporateTemplates = 'getCorporateEmailTemplates',
        DeleteSuccess = 'Email Template successfully Deleted',
        DeleteFailed = "Failed to Delete Email Template",
        DeleteError = "An error occurred while Deleting the Email Template",
        Success = "Success",
        EnqueueApi = 'Email/EnQueue',
        SaveSuccess = "Successfully saved the Email Template",
        SaveFailed = "Email Template failed to Save",
        SaveError = "An error occurred while saving the Email Template",
        LowerCaseApi = 'emailTemplate',
        SubsApi = 'Email/PreviewSubstitution',
        RetrieveFail = 'Unable to retrieve Email Templates.'
    };

    export enum TemplatePreview {
        TemplateDeleted = 'The template you are looking for has been deleted.'
    };

    export enum TemplateFilter {
        EventTypeCategory = 'Template Event',
        LinesOfBusiness = 'Lines of Business',
        TemplateType = 'Template Type',
    }

    export enum TemplateInput {
        All = 'All',
        EmailType = 'emailType',
        Content = 'content',
        TContent = 'Content',
        TemplateType = 'templateType',
        TType = 'Template Type',
        TEvent = 'Template Event',
        Event = 'emailAutomationEventTypeCategory', //email events?
        Title = 'title',
        TTitle = 'Template Title',
        Subject = 'subject',
        ESubject = 'Email Subject',
        IDraft = 'isDraft',
        Draft = 'Draft',
        Description = 'description',
        TDescription = 'Template Description',
        TLOB = 'Lines of Business',
        LOB = 'linesOfBusiness',
        Language = 'language',
        TLanguage = 'Template Language',
        Hint = 'Variable usage for template subject and body: {! Attempt1.Info; Attempt2.Info; ... ; Default Text}',
        SelectVar = 'Select a Variable',
        LeftSub = ' {!',
        RightSub = '}',
        TUpdatedOn = 'Updated On',
        UpdatedOn = 'updatedOn',
    };

    export enum Tooltips {
        Sort = 'Sort'
    };

    export enum UpsertAgentForm {
        AgentCode = 'Agent Code',
        FirstName = 'First Name',
        LastName = 'Last Name',
        PreferredName = 'Preferred Name',
        ActiveChannel = 'Active Channel',
        Agency = 'Agency',
        Timezone = 'Time Zone',
    };

    export enum SaveText {
        Next = 'Next',
        Save = 'Save',
        Link = 'Link',
        Unlink = 'Unlink'
    };

    export enum YesNoOptionDisplay {
        Yes = 'Yes',
        No = 'No',
    }

    export enum FollowUpDuration {
        Fifteen = '15 minutes',
        Thirty = '30 minutes',
        FourtyFive = '45 minutes',
        OneHour = '1 hour',
        OneHrThirty = '1 hour and 30 minutes',
        TwoHours = '2 hours'
    }

    export enum WidgetTitles {
        Activities = 'Today\'s To-Do List',
        Crm = 'CRM',
        Finance = 'Finance',
        Help = 'Help & Support',
        Notifications = 'Notifications',
        LicenseAppointment = 'Licenses & Appointments',
        CommissionStatement = 'Commission Statement',
        Performance = 'Performance',
        Podcasts = 'Podcasts',
        Tools = 'Tools',
    };

    export enum LicenseAppointment {
        License = 'Licenses',
        Appointment = 'Appointments',
        LicenseTab = 'LicenseTab',
        Status = 'Status',
        Expiration = 'Expiration',
        LineOfAuthority = 'Line Of Authority',
        Perpetual = "Perpetual",
        AppointmentTab = 'AppointmentTab',
        LicenseRoute = '/licenses',
        AppointmentRoute = '/appointments',
        GetAgentLicenseEndpoint = 'agent/license/list',
        GetAgentStatesEndpoint = 'agent/license/states',
        LicenseStatusDialogueTitle = 'License Status Legend',
        AppointmentStatusDialogueTitle = 'Appointment Status Legend',
        GetAgentLicenseLineOfAuthoritiesEndpoint = 'agent/license/lineOfAuthorities',
        GetAgentAppointmentCountByStateEndpoint = 'agent/appointment/activeAppointmentsCountByLOB',
        GetAgentAppointmentEndpoint = 'agent/appointment/list',
        GetAgentAppointmentLineOfBusinessEndpoint = 'agent/appointment/lineOfBusinesses',
        GetAgentAppointmentStateEndpoint = 'agent/appointment/states',
        LineOfBusiness = 'Line Of Business',
        HASSLinkPath = '/_layouts/15/SsoPages/Haassso.aspx'
    };

    export enum AppointmentLineOfBusinessHeading {
        'Commercial Health' = 'Health',
        'Medicare' = 'Medicare',
        'Supplemental' = 'Supp.',
        'Life' = 'Life'
    }

    export enum LicenseStatusIcon {
        Active = 'Active',
        Pending = 'Pending',
        Terminated = 'Terminated',
    };
    export enum AppointmentStatusIcon {
        Appointed = 'Appointed',
        Pending = 'Pending',
        Terminated = 'Terminated'
    }

    export enum LicenseAppointmentFilterLabel {
        All = 'All',
    };

    export enum AppointmentLineOfBusiness {
        CommercialHealth = 'Commercial Health',
        Medicare = 'Medicare',
        Supplemental = 'Supplemental',
        Life = 'Life'
    }

    export enum AgentCommissionStatement {
        StatementDate = 'Statement Date',
        BalanceSummary = 'Agent Balance Summary:',
        BalanceBeginning = 'Agent Balance - Beginning',
        PersonalEarnedComm = 'Earned Commission - Personal',
        PersonalFirstYear = 'First Year',
        PersonalRenewal = 'Renewal',
        UnearnedBalanceLapsePolicy = 'Unearned Balance on Lapse Policy',
        OverridesEarnedComm = 'Earned Commision - Overrides',
        OverridesFirstYear = 'First Year',
        OverridesRenewal = 'Renewal',
        Adjustments = 'Adjustments',
        BalanceEnding = 'Agent Balance - Ending',
        UnearnedBalanceClosing = 'Unearned Balance',
        GetAgentCommissionStatementDatesEndpoint = 'agent/commission/statementDates',
        GetAgentCommissionStatementSummaryEndpoint = 'agent/commission/statementSummary'
    }
}