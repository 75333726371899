import React from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
    Tab
} from '@hmkts/rise';
import { Strings } from '../../assets/common/strings';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useHeaderBarStyles } from '../../utilities/hooks/styles';
import { IconButton, Toolbar, AppBar } from '@material-ui/core';
import { nav } from '../..';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { LicenseListContainer } from './license/license';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { LicenseFilter } from './license/license_filter';
import { AppointmentListContainer } from './appointment/appointment';
import { AppointmentFilter } from './appointment/appointment_filter';
import { useStylesLicenseAppointment } from '../../utilities/agentLicenseAppointment_util';

const LicenseAppointmentPage = () => {

    const location = useLocation();
    const getTabValue = () => {
        switch (location.pathname) {
            case Strings.LicenseAppointment.LicenseRoute:
                return Strings.LicenseAppointment.LicenseTab
            case Strings.LicenseAppointment.AppointmentRoute:
                return Strings.LicenseAppointment.AppointmentTab
            default:
                return Strings.LicenseAppointment.LicenseTab
        }
    }

    const headerStyles = useHeaderBarStyles();
    const history = useHistory();

    const handleTabChange = (event, newTabValue) => {
        const newPath = newTabValue === Strings.LicenseAppointment.LicenseTab ? Strings.LicenseAppointment.LicenseRoute : Strings.LicenseAppointment.AppointmentRoute;
        if (history.location.pathname !== newPath) {
            history.push(newPath);
        }
    }

    const classes = useStylesLicenseAppointment();
    return (
        <TabContext
            value={getTabValue()}
        >
            <AppBar position="sticky" className={classnames(headerStyles.depth, headerStyles.top)}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={nav.goBack}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <TabList
                        onChange={handleTabChange}
                    >
                        <Tab
                            label={Strings.LicenseAppointment.License}
                            value={Strings.LicenseAppointment.LicenseTab}
                            key={Strings.LicenseAppointment.LicenseTab}
                        />

                        <Tab
                            label={Strings.LicenseAppointment.Appointment}
                            value={Strings.LicenseAppointment.AppointmentTab}
                            key={Strings.LicenseAppointment.AppointmentTab}
                        />

                    </TabList>
                </Toolbar>
            </AppBar>

            {getTabValue() === Strings.LicenseAppointment.LicenseTab && (<LicenseFilter />)}

            {getTabValue() === Strings.LicenseAppointment.AppointmentTab && (<AppointmentFilter />)}

            <TabPanel
                className={classes.tabRoot}
                value={Strings.LicenseAppointment.LicenseTab}
                key={Strings.LicenseAppointment.LicenseTab}
            >
                <DesktopPadding>
                    <LicenseListContainer></LicenseListContainer>
                </DesktopPadding>
            </TabPanel>

            <TabPanel
                value={Strings.LicenseAppointment.AppointmentTab}
                key={Strings.LicenseAppointment.AppointmentTab}
            >
                <DesktopPadding>
                    <AppointmentListContainer></AppointmentListContainer>
                </DesktopPadding>
            </TabPanel>
        </TabContext>
    );
}

export const LicenseAppointmentContainer = LicenseAppointmentPage;