import { Button, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from '@hmkts/rise';
import { Widget } from './base_widget';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import { themePalette } from '../../utilities/branding';
import CommissionStatementSvg from '../svgs/icons/commissionStatement';
import { useLinkToExternal } from '../../utilities/hooks';
import { getCommissionStatementDates } from '../../selectors/agent_commission_statement_selectors';
import { CommissionStatementTile } from '../agentCommissionStatement/commission_statement_tile';
import { getCurrentAgentCode } from '../../selectors/agent_selectors';
import { GetAgentCommissionStatementDates } from '../../actions/commission_statement_actions';
import { getResourceCenterCommissionSummaryReportUrl } from '../../utilities/url_util';

interface StateProps {
	loading: boolean;
	currentAgentCode: string;
	agentCommissionStatementDates: Date[];
}

interface DispatchProps {
	getAgentCommissionStatementDates: () => void
}

type Props = StateProps & DispatchProps;

const CommissionStatementWidget: React.FC<Props> = (props) => {

	let { loading, currentAgentCode, agentCommissionStatementDates, getAgentCommissionStatementDates } = props;

	useEffect(() => {
		if (currentAgentCode != 'unknown') {
			getAgentCommissionStatementDates();
		}
	}, [currentAgentCode]);

	const navToCommissionStatement = useLinkToExternal(getResourceCenterCommissionSummaryReportUrl());

	const renderWidget = () => {
		if (loading) {
			return (
				<Typography align='center'>Loading...</Typography>
			);
		} else if (agentCommissionStatementDates && agentCommissionStatementDates.length > 0) {
			return (
				<CommissionStatementTile />
			);
		} else {
			return null;
		}
	};

	return (
		<Widget
			title={Strings.WidgetTitles.CommissionStatement}
			avatar={<CommissionStatementSvg width={30} height={30} fill={themePalette.menuSvgCommissionStatement} />}
			actionButtons={[<Button disabled={agentCommissionStatementDates == undefined || agentCommissionStatementDates.length <= 0} onClick={navToCommissionStatement}>View All</Button>]}>
			{renderWidget()}
		</Widget>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	loading: state.commissionStatement.loading,
	currentAgentCode: getCurrentAgentCode(state),
	agentCommissionStatementDates: getCommissionStatementDates(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getAgentCommissionStatementDates: () => dispatch(GetAgentCommissionStatementDates.started()),
});

export const CommissionStatementWidgetContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(CommissionStatementWidget);